import React from "react";

const Mainimage = () => {
    return (
        <div className="fullwidth">
            <div className="ppltitle">
                <div className="titles">
                    <img src="./images/logo.png" alt="mon logo"/>
                    <h1>MAISON D'ASSISTANTS MATERNELS</h1>
                    <h2>Bien grandir, naturellement</h2>
                </div>
            </div>
            <div className="pplimage"></div>
        </div>
    );
}

export default Mainimage;